import * as React from 'react';

import { useBulkTriggerSecurityEventNotification } from 'security-app/hooks/useSecurityEventsAPI';
import SendNotificationModal
  from 'security-app/components/SecurityEvents/Alerts/AlertsModals/NotificationsModal/SendNotificationModal';

type Props = {
  eventIds: Array<string>,
  onClose: () => void,
  show: boolean,
}

const SendNotificationBulk = ({ eventIds, onClose, show }: Props) => {
  const { triggerSecurityEventNotifications, triggeringSecurityEventNotifications } = useBulkTriggerSecurityEventNotification();

  const onConfirm = async (notificationId: string) => {
    await triggerSecurityEventNotifications({ ids: eventIds, notificationId: notificationId });

    onClose();
  };

  return (
    <SendNotificationModal confirmDisabled={triggeringSecurityEventNotifications} closeModal={onClose} onConfirm={onConfirm} show={show} />
  );
};

export default SendNotificationBulk;
