import __request__ from 'routing/request';
interface InitProgress {
    readonly percentage: string;
    readonly estimated_minutes_left: number;
    readonly needed_shingles: number;
}
interface Entity {
    readonly name: string;
    readonly value: Object;
}
interface AnomalySource {
    readonly detector_id: string;
    readonly confidence: number;
    readonly task_id: string;
    readonly model_id: string;
    readonly schema_version: number;
    readonly anomaly_score: number;
    readonly execution_start_time: string;
    readonly data_end_time: string;
    readonly data_start_time: string;
    readonly feature_data: FeatureData[];
    readonly execution_end_time: string;
    readonly approx_anomaly_start_time: string;
    readonly anomaly_grade: number;
    readonly entity: Entity[];
}
interface DetectorConfig {
    readonly error_message: string;
    readonly last_anomaly_results_retrieved: string;
    readonly queue_place: number;
    readonly event_definition_enabled: boolean;
    readonly event_procedure_id: string;
    readonly category_field: string[];
    readonly description: string;
    readonly _scope: string;
    readonly was_created: boolean;
    readonly remediation_steps: string;
    readonly title: string;
    readonly enabled: boolean;
    readonly time_field: string;
    readonly indices: string[];
    readonly window_delay_minutes: number;
    readonly feature_fields: FeatureField[];
    readonly opensearch_detector_id: string;
    readonly name: string;
    readonly current_status: string;
    readonly event_definition_id: string;
    readonly shingle_size: number;
    readonly id: string;
    readonly detector_interval_minutes: number;
}
interface SearchHit {
    readonly _seq_no: number;
    readonly _index: string;
    readonly _source: AnomalySource;
    readonly _id: string;
    readonly _score: number;
    readonly _primary_term: number;
    readonly _version: number;
}
interface DetectorConfigResponse {
    readonly name: string;
    readonly description: string;
    readonly id: string;
    readonly remediation_steps: string;
    readonly enabled: boolean;
    readonly status: string;
}
interface AnomalySearchResults {
    readonly _shards: Shards;
    readonly hits: SearchHits;
    readonly took: number;
    readonly timed_out: boolean;
}
interface FeatureData {
    readonly feature_id: string;
    readonly feature_name: string;
    readonly data: number;
}
interface DetectorProfile {
    readonly models: Model[];
    readonly ad_task: Object;
    readonly total_entities: number;
    readonly model_count: number;
    readonly shingle_size: number;
    readonly coordinating_node: string;
    readonly active_entities: number;
    readonly state: string;
    readonly error: string;
    readonly total_size_in_bytes: number;
    readonly init_progress: InitProgress;
}
interface DetectorDefinition {
    readonly event_definition_enabled: boolean;
    readonly event_procedure_id: string;
    readonly category_field: string[];
    readonly description: string;
    readonly remediation_steps: string;
    readonly title: string;
    readonly time_field: string;
    readonly indices: string[];
    readonly window_delay_minutes: number;
    readonly feature_fields: FeatureField[];
    readonly name: string;
    readonly event_definition_id: string;
    readonly shingle_size: number;
    readonly detector_interval_minutes: number;
}
type DetectorConfigArray = DetectorConfig[];
interface Total {
    readonly value: number;
    readonly relation: string;
}
interface Model {
    readonly model_size_in_bytes: number;
    readonly model_id: string;
    readonly entity: {
        readonly [_key: string]: Object;
    }[];
    readonly node_id: string;
}
interface DetectorConfigUpdateRequest {
    readonly id: string;
    readonly enabled: boolean;
}
interface SearchHits {
    readonly hits: SearchHit[];
    readonly total: Total;
    readonly max_score: number;
}
interface FeatureField {
    readonly feature_name: string;
    readonly operation: string;
    readonly field_name: string;
}
interface Shards {
    readonly total: number;
    readonly failed: number;
    readonly successful: number;
    readonly skipped: number;
}
/**
 * Create a new Anomaly Detector config.
 * @param config config
 */
export function createDetectorDefinition(config?: DetectorDefinition): Promise<DetectorDefinition> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/detectors/config', config, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all Anomaly Detector Configurations.  Use to get the configuration for the current state of detectors.
 */
export function getAnomalyDetectorConfig(): Promise<DetectorConfigArray> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/detectors/config', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Fetch a Detector Definition by name
 * @param id id
 */
export function getDetectorConfigByName(id?: string): Promise<DetectorConfig> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/detectors/config/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete Anomaly Detector config.
 * @param id id
 */
export function deleteDetector(id?: string): Promise<void> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.securityapp/detectors/config/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update an existing Anomaly Detector config.
 * @param definition definition
 * @param id id
 */
export function updateDetector(definition?: DetectorDefinition, id?: string): Promise<DetectorDefinition> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/detectors/config/${id}`, definition, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update Detector Event Definition Status
 * @param update update
 */
export function updateDetectorEventDefinitionStatus(update?: DetectorConfigUpdateRequest): Promise<void> {
    return __request__('PUT', '/plugins/org.graylog.plugins.securityapp/detectors/event_definition/update_status', update, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update Detector Status
 * @param update update
 */
export function updateDetectorStatus(update?: DetectorConfigUpdateRequest): Promise<DetectorConfigResponse> {
    return __request__('PUT', '/plugins/org.graylog.plugins.securityapp/detectors/update_status', update, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Search anomalies detected by given Anomaly Detector.  This endpoint is primarily intended for testing purposes.
 * @param detectorId detectorId
 * @param startDate startDate
 * @param endDate endDate
 * @param pageNumber pageNumber
 * @param pageSize pageSize
 */
export function searchAnomalies(detectorId?: string, startDate?: string, endDate?: string, pageNumber: number = 0, pageSize: number = 500): Promise<AnomalySearchResults> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/detectors/${detectorId}/anomalies/${startDate}/${endDate}`, null, { 'pageNumber': pageNumber, 'pageSize': pageSize }, {
        'Accept': ['application/json']
    });
}
/**
 * Get the Anomaly Detector Profile for the given detector ID.
 * @param detectorId detectorId
 */
export function getDetectorProfile(detectorId?: string): Promise<DetectorProfile> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/detectors/${detectorId}/profile`, null, {}, {
        'Accept': ['application/json']
    });
}
