import * as React from 'react';

import { useBulkSetOwnerForSecurityEvents } from 'security-app/hooks/useSecurityEventsAPI';
import UsersModal from 'security-app/components/SecurityEvents/Alerts/AlertsModals/UsersModal/UsersModal';
import { useTableFetchContext } from 'components/common/PaginatedEntityTable';

type Props = {
  eventIds: Array<string>,
  onClose: () => void,
  show: boolean,
}

const SetOwnerBulk = ({ eventIds, onClose, show }: Props) => {
  const { setOwner, settingOwners } = useBulkSetOwnerForSecurityEvents();
  const { refetch } = useTableFetchContext();

  const onConfirm = async (ownerId: string) => {
    await setOwner({ ids: eventIds, ownerId });
    refetch();

    onClose();
  };

  return (
    <UsersModal confirmDisabled={settingOwners} closeModal={onClose} onConfirm={onConfirm} show={show} isLoading={settingOwners} />
  );
};

export default SetOwnerBulk;
