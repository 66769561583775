import * as React from 'react';

import { useModalContext } from 'security-app/components/common/Modal/ModalContext';
import { useUpdateSecurityEvent } from 'security-app/hooks/useSecurityEventsAPI';
import UsersModal from 'security-app/components/SecurityEvents/Alerts/AlertsModals/UsersModal/UsersModal';

function SetOwner() {
  const { setModal, entity: securityEvent, setEntity } = useModalContext();
  const { updateSecurityEvent, updatingSecurityEvent } = useUpdateSecurityEvent();

  const onCancel = () => {
    setModal(null);
    setEntity(null);
  };

  const assignOwner = async (ownerId: string) => {
    const payload = {
      owner: ownerId,
      notes: securityEvent.notes,
      status: securityEvent.status,
    };

    await updateSecurityEvent({ id: securityEvent.event.id, data: payload });

    setModal(null);
    setEntity(null);
  };

  return (
    <UsersModal show
                confirmDisabled={updatingSecurityEvent}
                owner={securityEvent.owner}
                closeModal={onCancel}
                onConfirm={assignOwner}
                isLoading={updatingSecurityEvent} />
  );
}

export default SetOwner;
