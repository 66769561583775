import __request__ from 'routing/request';
interface PriorityResponse {
    readonly default: boolean;
    readonly id: string;
    readonly text: string;
    readonly priority: number;
}
interface PrioritizeRequest {
    readonly priorities: Priority[];
}
interface Priority {
    readonly id: string;
    readonly text: string;
    readonly priority: number;
}
type PriorityResponseArray = PriorityResponse[];
/**
 * Get a list of investigation priorities
 * @param sort The field to sort the result on
 * @param direction The sort direction
 */
export function listPriorities(sort: 'text' | 'priority' = 'priority', page: number = 1, per_page: number = 15, query?: string, direction: 'asc' | 'desc' = 'asc'): Promise<unknown[]> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/investigations/priorities', null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'direction': direction }, {
        'Accept': ['application/json']
    });
}
/**
 * Create a new investigation priority
 */
export function create(priority: string): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/investigations/priorities', priority, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all possible investigation priority values
 */
export function getAll(): Promise<PriorityResponseArray> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/investigations/priorities/all', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Remove default investigation priority
 */
export function removeDefault(): Promise<unknown> {
    return __request__('DELETE', '/plugins/org.graylog.plugins.securityapp/investigations/priorities/default', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Reprioritize the weight of existing priorities
 */
export function updatePriorities(priorities: PrioritizeRequest): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/investigations/priorities/reprioritize', priorities, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update an investigation priority
 */
export function update(id: string, priority: string): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/investigations/priorities/${id}`, priority, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete an investigation priority
 */
export function remove(id: string): Promise<unknown> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.securityapp/investigations/priorities/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Set default investigation priority
 */
export function setDefault(id: string): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/investigations/priorities/${id}/default`, null, {}, {
        'Accept': ['application/json']
    });
}
